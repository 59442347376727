import React, { useState } from "react";
import Container from "@layout/container";
import HeroBanner from "../../components/common/heroBanner";
import CostIntroduction from "../../components/cost/costIntroduction/CostIntroduction";
import GridCards from "../../components/common/gridCards/GridCards";
import { costCards } from "../../components/cost/content";
import VideoLightBox from "../../components/common/videoLightBox/VideoLightBox";
import Meta from "../../components/seo/Meta";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Cost = (props) => {
  const [isVideoPlaying, toggleVideoPlaying] = useState(false);
  const siteUrl = props?.data?.allSite?.nodes[0].siteMetadata?.siteUrl;
  const metaData = {
    title: `Harness コスト管理 ｜Harness正規販売代理店 DXable`,
    description: `Harnessのクラウドコスト管理では、エンジニアリングチームとDevOpsチームがKubernetesとAWS ECSごとの詳細なコストの視覚化が可能です。クラウド予算を設定し、コストを管理し、クラウドイベントとコストの相関関係を見える化できます。`,
    keywords: `Harness,コスト管理,harness,Harness.io,harness.io,ハーネス,DevOps,Cloud Cost Management,クラウドコスト管理,Harness代理店,Kubernetes, AWS ECS,CCM`,
    url: `${siteUrl}${props.path}`,
  };

  return (
    <>
      <Meta meta={metaData} />
      <Container>
        <HeroBanner
          title={<BannerTitle />}
          paragraph={<BannerParagraph />}
          media={
            <MediaVideo
              isVideoPlaying={isVideoPlaying}
              toggleVideoPlaying={toggleVideoPlaying}
            />
          }
        />
        <section className="w-fit mb-[120px] mx-auto">
          <StaticImage
            src="../../images/cost/ccm-banner-img.png"
            width={1180}
            height={460}
            alt="Feature Flags Banner Image"
            placeholder="dominantColor"
            quality={100}
          />
        </section>
        <CostIntroduction />
        <GridCards cards={costCards} bg="#fff" />
      </Container>
    </>
  );
};

const BannerTitle = () => {
  return <span>Harness Cloud Cost Management</span>;
};

const BannerParagraph = () => {
  return (
    <span>
      完全自動化とコストの透明化で無駄を排除。
      <br />
      <br />
      インテリジェントなクラウドコストの自動化により、時間を節約し、労力を削減し、クラウド費用の最大70%削減が可能です。クラウド費用の異常値を検知して停止させ、想定外の請求額を回避することができます。
    </span>
  );
};

const MediaVideo = ({ isVideoPlaying, toggleVideoPlaying }) => {
  return (
    <>
      <button onClick={() => toggleVideoPlaying(!isVideoPlaying)}>
        <StaticImage
          src={"../../images/cost/ccmBanner.svg"}
          loading="eager"
          layout="fullWidth"
          quality={100}
          alt="Cost Page Panner"
          className="w-full hover:cursor-pointer"
        />
      </button>
      {isVideoPlaying && (
        <VideoLightBox
          src={"https://www.youtube.com/embed/p_7TKc6fdxU"}
          title={"YouTube video player"}
          toggleVideoPlaying={toggleVideoPlaying}
          isVideoPlaying={isVideoPlaying}
        />
      )}
    </>
  );
};

export const costPageData = graphql`
  query fetchCostPageData {
    allSite {
      nodes {
        siteMetadata {
          siteUrl
          assetsBaseUrl
        }
      }
    }
  }
`;

export default Cost;
