import React from "react";
import { costDetails } from "../content";

const CostIntroduction = () => {
  return (
    <section className="cost-intro">
      <div className="cost-intro__head">
        <h1 className="cost-intro__head__title">優れたチームに必要な機能</h1>
        <p className="cost-intro__head__description">
          Harness Cloud Cost
          Managementは、エンタープライズクラスのプラットフォームに期待される標準的なコスト管理機能を全て備えているだけではありません。Harnessでは、クラウドのコストの詳細を可視化しつつ、積極的にコストを削減するために、全てを自動化することに重点を置いています。
        </p>
      </div>
    </section>
  );
};

export default CostIntroduction;
