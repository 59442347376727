import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const costCards = [
  {
    id: 1,
    title: "クラウド自動停止",
    paragraph:
      "クラウド自動停止機能で最大70%を節約できます。カスタムスクリプトや手作業によるエンジニアリングを必要とせず、クラウドリソースのアイドル時間を積極的に管理。これにより、エンジニアは最も重要なこと、つまり収益を生み出すアプリケーション開発に集中できるようになります。",
    isSVG: true,
    icon: (
      <StaticImage
        src="../../images/cost/ccm-feature-one.svg"
        width={80}
        height={60}
        alt="クラウド自動停止"
        placeholder="dominantColor"
        quality={100}
      />
    ),
  },
  {
    id: 2,
    title: "Kubernetesコストの詳細な可視化",
    paragraph:
      "Kubernetesコスト、クラスター使用率、リソース所有権についてより深く掘り下げます。ノードプールとクラスターコンピュートリソースの適切なサイズ設定に関する実用的なインサイトと推奨事項により、Kubernetesワークロードの最適化に必要なツールを提供します。",
    isSVG: true,
    icon: (
      <StaticImage
        src="../../images/cost/ccm-feature-two.svg"
        width={80}
        height={60}
        alt="Kubernetesコストの詳細な可視化"
        placeholder="dominantColor"
        quality={100}
      />
    ),
  },
  {
    id: 3,
    title: "コストパースペクティブ",
    paragraph:
      "使いやすく、すぐ使える直感的なクラウドコストダッシュボード（カスタマイズ可能）と、カスタマイズされたコストパースペクティブにより、ビジネスニーズの文脈でクラウドコストの指標を確認することができます。プロジェクト、チーム、ビジネスユニット、部門など、カスタマイズ可能なコストカテゴリーごとにグループ化された詳細なコスト情報を確認し、クラウドコストの完全な透明性を実現できます。",
    isSVG: true,
    icon: (
      <StaticImage
        src="../../images/cost/ccm-feature-three.svg"
        width={80}
        height={60}
        alt="コストパースペクティブ"
        placeholder="dominantColor"
        quality={100}
      />
    ),
  },
  {
    id: 4,
    title: "リソースタグの自動化",
    paragraph:
      "もう手作業でタグ付けする必要はありません Cloud Cost Managementを開発パイプラインに統合し、CDワークフローの一部としてリソース割り当てとタグ付けを自動化できます。Harness CDと組み合わせることで、自動的かつプロアクティブなリソースタグ管理が実現します。",
    isSVG: true,
    icon: (
      <StaticImage
        src="../../images/cost/ccm-feature-four.svg"
        width={80}
        height={60}
        alt="リソースタグの自動化"
        placeholder="dominantColor"
        quality={100}
      />
    ),
  },
];
